<template>
  <div>
    <div class="super">
      <div class="holder">
        <div>
          <Header />

          <div class="faq_holder container-fluid">
            <div class="faq_header">
              <h1>FAQs</h1>
            </div>
            <div class="faq_wrap">
              <div class="qa">
                <h3 class="question">
                  How much will it cost me to release my property to Shelta?
                </h3>
                <h6 class="answer">
                  Shelta takes a commission of 10% of the rented value of the
                  property.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  What am I expected to do after registering my property?
                </h3>
                <h6 class="answer">
                  Rest. Shelta’s got you covered.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Would the landlord fix all damages?
                </h3>
                <h6 class="answer">
                  Landlords are expected to fix structural damages.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Does Shelta directly contact Landlords by phone?
                </h3>
                <h6 class="answer">
                  Yes, we do.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  How do I get my money/rents from tenants?
                </h3>
                <h6 class="answer">
                  Landlord’s rent is paid through his/her registered bank
                  account year-on-year.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Is my rent payment guaranteed?
                </h3>
                <h6 class="answer">
                  Absolutely! Our funding funnel handles all your rent payment
                  when due.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  What kind of property can be registered?
                </h3>
                <h6 class="answer">
                  Shelta registers tenantable properties with rent value of not
                  less than N200, 000. 00 (two hundred thousand naira)
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  How do I check that my property is compliant?
                </h3>
                <h6 class="answer">
                  You can call us to book a date for inspection of the property.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Can I register my property when it’s occupied?
                </h3>
                <h6 class="answer">
                  Yes, however, existing tenants must register with Shelta.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Will Shelta keep my money safe?
                </h3>
                <h6 class="answer">
                  Your money is paid into your bank account except on express
                  instruction that Shelta keeps it for you.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Can I visit my property when it’s occupied?
                </h3>
                <h6 class="answer">
                  Shelta handles all visits, inspections, etc. while the
                  property is occupied. Landlord cannot enter the property.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Is there a tenant screening process?
                </h3>
                <h6 class="answer">
                  Yes, all tenants go through our thorough verification process.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Why do I need to provide personal documents?
                </h3>
                <h6 class="answer">
                  Personal documents are required in order to verify ownership
                  of property.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Can I get my rent in installments?
                </h3>
                <h6 class="answer">
                  Yes, you can get your rent in installments if you prefer.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  How do I get tenants?
                </h3>
                <h6 class="answer">
                  That’s our job. Just rest easy.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Do I have any legal obligation?
                </h3>
                <h6 class="answer">
                  Signing our property management agreement is sufficient.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Does Shelta have litigation coverage?
                </h3>
                <h6 class="answer">
                  Yes. Shelta gives litigation coverage up to the tune of N2,
                  000, 000 (two million naira).
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  What if a tenant refuses to pay rent?
                </h3>
                <h6 class="answer">
                  We have ready prepared measures to handle such cases if and
                  when they occur.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Can I increase the rent?
                </h3>
                <h6 class="answer">
                  Yes. However, it cannot be within the duration of a running
                  contract.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  What is Rent Financing?
                </h3>
                <h6 class="answer">
                  It’s the funnel used in ensuring guaranteed rents not minding
                  the flexible payment we give to tenants.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Can I register more than one property?
                </h3>
                <h6 class="answer">
                  Yes. A landlord is free to register as many properties as
                  desired.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Do I need official tenancy agreement?
                </h3>
                <h6 class="answer">
                  Shelta handles the tenancy agreement with the tenants.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Who will pay for electricity bills, water bills, etc.?
                </h3>
                <h6 class="answer">
                  Tenants pay all utility bills during their stay on the
                  property.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Will my property be inspected?
                </h3>
                <h6 class="answer">
                  Yes, Shelta inspects properties and we send you our inspection
                  report.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  What if I want to remove a tenant?
                </h3>
                <h6 class="answer">
                  Shelta handles that.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  How much service charge do I pay Shelta for managing my
                  property?
                </h3>
                <h6 class="answer">
                  Shelta charges only 10% to provide guaranteed rent, litigation
                  cover and property management service.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  What if the tenant damages the property?
                </h3>
                <h6 class="answer">
                  Shelta has already prepared measures in place to handle such
                  scenarios without any pressure on the landlord.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  Would the landlord pay for maintenance and repair?
                </h3>
                <h6 class="answer">
                  Structural maintenance is the responsibility of the landlord
                  while maintenance from wear and tear is handled by the
                  property user (and Shelta) at the time of wear and tear.
                  Please check our terms of use.
                </h6>
              </div>
              <div class="qa">
                <h3 class="question">
                  How can I monitor my property?
                </h3>
                <h6 class="answer">
                  Shelta has a robust tech-dashboard that captures and displays
                  every transaction and activities happening on your property so
                  you can rest easy.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import $ from "jquery";
import { Service } from "../store/service";
const Api = new Service();

export default {
  // props: ["year", "month", "date", "hour", "minutes", "seconds", "milliseconds"],
  // props: ["2021", "2", "28", "10", "8", "9", "12"],

  name: "Faq",
  components: {
    Header,
    FooterMain
  },

  data() {
    return {};
  },

  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;

// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.faq_holder {
  margin-top: 8rem;
  // margin: 10px;
  // padding-left: 15rem;
  // padding-right: 15rem;
  // text-align: left;
}
.faq_header {
  h1 {
    font-family: Gotham;
    font-weight: bold;
    font-style: normal;
    font-size: 40px;
    line-height: 60px;
  }
}

.qa {
  margin: 20px 0;
  h3 {
    font-family: Gotham;
    font-weight: 800;
    font-style: normal;
    font-size: 23px;
    line-height: 40px;
  }
  h6 {
    font-family: Gotham;
    font-weight: 200;
    font-style: normal;
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media only screen and (max-width: 599px) {
  .faq_holder {
    text-align: left;
    // margin-top: 6rem;
    // padding-left: 15px;
    // padding-right: 15px;
  }
  .faq_header {
    h1 {
      font-size: 25px;
    }
  }
  .qa {
    h3 {
      font-family: Gotham;
      font-weight: 800;
      font-style: normal;
      font-size: 20px;
      line-height: 30px;
    }
    h6 {
      font-family: Gotham;
      font-weight: 200;
      font-style: normal;
      font-size: 17px;
      line-height: 25px;
    }
  }
}

@media only screen and (min-width: 991px) {
  .count-down-child {
    padding: 0 10rem;
  }
  .faq_holder {
    padding-left: 15rem;
    padding-right: 15rem;
    text-align: left;
  }
}
</style>
